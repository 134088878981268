<template>
  <div class="registered">
    <div class="reg-content">
      <h1 class="reg-content-title">修改个人/企业密码</h1>
      <div class="from-box">
        <!-- <div class="input-box enterprise-name">
          <input type="text" class="input-text" placeholder="请输入企业名称">
        </div>
        <div class="tip-err" v-if="regType==2">当前企业信息已存在</div> -->
        <div class="input-box user-phone">
          <input type="number" class="input-text" placeholder="请输入联系人电话号码" v-model="formData.userPhone">
        </div>
        <div class="tip-err" v-if="phoneErr!=''">{{phoneErr}}</div>
        <div class="input-box ver-code">
          <input type="text" class="input-text" placeholder="请输入验证码" v-model="code">
          <!-- <span class="get-ver-code" :class="this.timer?'get':''" @click="getCode">{{getCodeBtnText}}</span> -->
          <span  :class="this.timer?'get-ver-code nums':'get-ver-code'" @click="getCode">{{getCodeBtnText}}</span>
        </div>
        <div class="tip-err" v-if="codeErr!=''">{{codeErr}}</div>
        <div class="input-box password">
          <input :type="inputType" class="input-text" placeholder="请输入密码" v-model="formData.password1">
          <div class="show-pass" :class="inputType=='text'?'open':'close'" @click="showPass(1)"></div>
        </div>
        <div class="input-box password">
          <input :type="inputType2" class="input-text" placeholder="请再次输入密码" v-model="formData.password2">
          <div class="show-pass" :class="inputType2=='text'?'open':'close'" @click="showPass(2)"></div>
        </div>
        <div class="tip-err" v-if="passErr!=''">{{passErr}}</div>
        <button class="submit-btn" :class="!btnType?'disabled':''" @click='subBtn'>确认修改</button>
      </div>
    </div>
    <!-- 密码修改成功弹窗 -->
    <div class="success-modal" v-if="showSuccessModal">
      <div class="success-content">
        <div class="success-close"></div>
        <div class="success-text">修改密码成功</div>
        <button class="toLogin" @click="toLogin">去登录</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'changePassword',
  data(){
    return {
      showSuccessModal: false,
      regType: 0, //0:欢迎注册，1：个人注册；2企业注册；
      inputType: 'password',     //密码框类型
      inputType2: 'password',    //再次输入密码框类型
      phoneErr: '',              //手机错误提示
      code: '',                  //验证码
      codeErr: '',               //验证码错误提示
      btnType: false,            //按钮状态
      nonNull: false,            //非空
      passErr: '',               //密码错误提示
      formData: {
        userPhone: '',             //用户手机
        password1: '',             //密码
        password2: '',             //再次输入密码
      },
      getCodeBtnText:'获取验证码',
      timer:'', //倒计时
    }
  },
  methods: {
    toLogin(){
      localStorage.setItem('needLogin', true);
      this.$router.push('/index');   
    },
    //切换注册类型
    switchType() {
      this.regType = (this.regType==1 ? '2' : '1');
    },
    //显示密码
    showPass(type){
      if(type == 1){
        this.inputType = this.inputType=='text'?'password':'text'
      }else{
        this.inputType2 = this.inputType2=='text'?'password':'text'
      }
    },
    //手机号验证
    phoneValidation(){
      if(this.formData.userPhone){
        let reg = /^0?1[3|4|5|7|8|9][0-9]\d{8}$/;

        if(!reg.test(this.formData.userPhone)){
          this.phoneErr = '请填写正确的手机号'
          this.btnType = false;
        }else{
          this.phoneErr = '';
          
          if(this.nonNull==true && this.passErr=='' && this.phoneErr==''){
            this.btnType = true;
          }
        }
      }
    },
    //密码验证
    pssValidation(){
      if(this.formData.password1 != this.formData.password2){
        this.btnType = false;
        this.passErr = '两次密码不一致';
      }else{
        this.passErr = '';

        if(this.nonNull==true && this.passErr=='' && this.phoneErr==''){
          this.btnType = true;
        }
      }
    },
    //获取验证码
    getCode(){
      if(this.formData.userPhone){
        this.$axios.post(`/v1/login/sendResetPwdSmsCode`, {
          phone: this.formData.userPhone
        }).then(res => {
          this.codeErr = res.msg;
          if(res.code==0){
            let time = 60;

            this.getCodeBtnText = time + '秒后再获取'
            this.timer = setInterval(()=>{
              time --;
              if(time == 0){
                this.getCodeBtnText = '获取验证码';
                clearInterval(this.timer);
                this.timer = '';
              }else{
                this.getCodeBtnText = time+'秒后再获取';
              }
            }, 1000);
          }
        })
      }else{
        this.phoneErr = '请填写手机号码'
      }
    },
    //表单验证
    formValidation(newName){
      if( newName.userPhone == '' || newName.password1 == '' || newName.password2 == '' || this.code == '' ){
        this.nonNull = false;
        this.btnType = false;
        return;
      }

      this.nonNull = true; //非空验证成功  

      if(this.passErr=='' && this.phoneErr==''){
        this.btnType = true;
      }
    },
    //提交
    subBtn(){
      this.$axios.post(`/v1/login/resetPassword`, {
        phone: this.formData.userPhone,
        password: this.formData.password1,
        confirm_password: this.formData.password2,
        code: this.code
      }).then(res => {
        if(res.code == 0){
          this.showSuccessModal = true;
        }
      })
    }
  },
  watch:{
    formData: {
      handler(newName,oldName){
          //表单验证
          this.formValidation(newName,oldName);
      },
      immediate: true,
      deep:true //为true，表示深度监听，这时候就能监测到a值变化
    },
    'formData.userPhone' :{
      handler(){
          this.phoneValidation();
      },
      immediate: true,
      deep:true //为true，表示深度监听，这时候就能监测到a值变化
    },
    'formData.password1' () {
      this.pssValidation();
    },
    'formData.password2' () {
      this.pssValidation();
    }
  }
}
</script>
<style scoped lang="scss">
.nums{
  color: #999 !important;
}
.registered{
  padding: 64px 0 88px;
  background-color: #F7F7F7;
}
.reg-content{
  box-sizing: border-box;
  width: 1200px;
  padding: 74px 116px 89px;
  background-color: #fff;
  margin: 0 auto;
}
.reg-content-title{
  font-size: 24px;
  font-weight: 900;
  color: #333;
  text-align: center;
}
.reg-title{
  font-size: 18px;
  font-weight: 800;
  color: #333;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.14);
  margin-bottom: 14px;
}
.from-box{
  width: 432px;
  padding-top: 50px;
}
.reg-btn{
  width: 100%;
  height: 40px;
  line-height: 40px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  font-size: 16px;
  color: #FFF;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.14);
  &.blue{
    background-color: #254ED4;
    cursor: pointer;
  }
  &.greey{
    background-color: #999;
  }
}

.submit-btn{
  width: 100%;
  height: 44px;
  border-radius: 6px;
  font-size: 16px;
  margin-top: 16px;
  background: #254ED4;
  color: #FFF;
  cursor: pointer;
  &.disabled{
    background: #999;
    cursor: default;
  }
}
.success-modal{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  .success-content{
    width: 374px;
    height: 286px;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    background: url(~@/assets/success-modal-bg.png) no-repeat 130px 27px #FFF;
    background-size: 164px 120px;
    position: relative;
  }
  .success-text{
    padding-top: 160px;
    font-size: 14px;
    color: #666;
    text-align: center;
    margin-bottom: 30px;
  }
  .toLogin{
    display: block;
    width: 286px;
    height: 44px;
    background: #254ED4;
    border-radius: 8px;
    font-size: 16px;
    color: #FFF;
    letter-spacing: 2px;
    margin: 0 auto;
    cursor: pointer;
  }
  .success-close{
    width: 30px;
    height: 30px;
    background: url(~@/assets/close-ico.png) no-repeat;
    background-size: 100%;
    position: absolute;
    right: -50px;
    top: 0;
    cursor: pointer;
  }
}
</style>